import React from 'react';
import Footer from '../../components/footer';
import { getMobileOS } from '../../utils/getClientOs';
import PlayStore from '../../assets/png/google-play.png';
import AppStore from '../../assets/png/app-store.png';

import './styles.iframegames.scss';

const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.childrenscenturyclassics.ccc';
const appStoreUrl = 'https://apps.apple.com/us/app/childrens-century-classics/id1212148723';

const IframeGames = () => {
  const mobileOs = getMobileOS();

  const onMobileAppStoreLinkClicked = () => {
    if (mobileOs.isAndroid) {
      window.location.href = playStoreUrl;
    } else if (mobileOs.isIos) {
      window.location.href = appStoreUrl;
    } else {
      window.location.href = '/';
    }
  };

  return (
    <div>
      <div className="iframe-games-container">
        <div className="message-box main-container">
          <h2 className="title">Update Your App</h2>
          <p className="descriptions">
            Mobile app you are using right now is of old version. Please update our app to the latest versions.
          </p>
          <div className="disclaimer">
            <span className="disclaimer-title">Disclaimer: </span>
            <span className="disclaimer-message">
              If updating the application does not work, Please uninstall current application and download new version
              from playstore.
            </span>
          </div>
          <div>Find our app in the following link</div>
          <a onClick={onMobileAppStoreLinkClicked} className="store-link">
            {' '}
            <img src={mobileOs.isIos ? AppStore : PlayStore} alt="" className="store-icon" />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IframeGames;
