/**
 * @function
 * @returns object containing property isAndroid true if userAgent is android and
 * isIos true if userAgent is ios
 */
export const getMobileOS = () => {
  const ua = navigator.userAgent;
  const mobileOs = { isAndroid: false, isIos: false };
  if (/android/i.test(ua)) {
    mobileOs.isAndroid = true;
  } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    mobileOs.isIos = true;
  }

  return mobileOs;
};
